import { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { Intercom } from '@capacitor-community/intercom'
import { Capacitor } from '@capacitor/core'
import { generateHmac } from '../../graphql'
import { UserType } from '../../types'

export const useIntercom = () => {
  const w = window as any
  const apollo = useApolloClient()
  const native = Capacitor.isNativePlatform()
  const [hmac, setHmac] = useState<string>()

  const getHmac = async () => {
    try {
      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: generateHmac
      })

      setHmac(data.generateHmac)

      return data.generateHmac
    } catch (err) {
      console.log(err)
    }
  }

  const updateIntercomUser = async (user: UserType) => {
    try {
      const hash = hmac || (await getHmac())

      if (!hash || !user) {
        return
      }

      const attributes = {
        name: user.displayName,
        user_id: user?.stripeCustomerId,
        user_hash: hash,
        email: user?.email,
        affiliate: !!user?.becameAffiliate,
        FB_URL: user?.facebookURL,
        IG_URL: user?.instagramURL,
        trial: user?.trial,
        has_trialed: user?.hasTrialed,
        experience: user?.experience,
        product: user?.onboarding?.product,
        industry: user?.onboarding?.industry,
        custom_industry: user?.onboarding?.customIndustry,
        // This attribute might be conflicting with Stripe's integration in Intercom
        // stripe_subscription_status: user.stripeSubscriptionStatus,
        subscription_interval: user.subscriptionInterval,
        stripe_customer_id: user.stripeCustomerId,
        post_frequency: user?.onboarding?.postFrequency,
        customer_frequency: user?.onboarding?.customerFrequency,
        confidence_level: user?.onboarding?.confidenceLevel,
        primary_platform: user?.onboarding?.primaryPlatform,
        preferred_feature: user?.onboarding?.preferredFeature,
        last_request_at: new Date().getTime() / 1000,
        user_created_at: user?.createdAt?.seconds,
        connected_profiles_amount: user?.socialProfiles?.length
      }

      if (native) {
        await Intercom.setUserHash({ hmac: hash })

        await Intercom.registerIdentifiedUser({
          userId: user?.stripeCustomerId,
          email: user.email
        })

        return await Intercom.updateUser(attributes)
      }

      w.Intercom('update', attributes)
    } catch (err) {
      console.log(err)
    }
  }

  const showIntercomMessenger = async () => {
    if (native) {
      return await Intercom.displayMessenger()
    }

    w.Intercom('show')
  }

  const updateIntercom = async (params: any) => {
    if (native) {
      return await Intercom.updateUser(params)
    }

    w.Intercom('update', params)
  }

  const logoutIntercom = async () => {
    if (native) {
      return await Intercom.logout()
    }

    w.Intercom('shutdown')
  }

  return {
    updateIntercom,
    updateIntercomUser,
    showIntercomMessenger,
    logoutIntercom
  }
}
