import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import firebase from 'firebase/app'
import { isEmpty } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { getContentPillars, getPersonas, getWorkspaces, socialProfileFindMany } from '../../graphql'
import { UserType } from '../../types'
import {
  getCurrentUserData,
  removeCookie,
  setCookie,
  setCurrentUserData,
  useIntercom,
  useSegment
} from '.'

declare global {
  interface Window {
    profitwell: any
  }
}

export const useUser = () => {
  const syncUserData = getCurrentUserData() || {}
  const apollo = useApolloClient()
  const location = useLocation()
  const { identify, page } = useSegment()
  const { updateIntercomUser, logoutIntercom } = useIntercom()
  const [user, setUser] = useState<UserType | null>(syncUserData)
  const [loaded, setLoaded] = useState<boolean>(!isEmpty(syncUserData))
  const [loading, setLoading] = useState(!isEmpty(syncUserData))
  const auth = firebase.auth()

  // Logs the user out of the platform
  const logout = async () => {
    await auth.signOut()
    removeCookie('userId')
    setCurrentUserData('')
    apollo.resetStore()
    await logoutIntercom()
    // window.location.reload()
  }

  // Refreshes the user's state
  const refresh = async () => {
    setLoading(true)

    const userProfile = auth.currentUser

    if (!userProfile) {
      return
    }

    const userData = await getUser()
    setUser(userData)
    setCurrentUserData(userData)
    setLoaded(true)
    setLoading(false)
  }

  const getSocialProfiles = async (user: string) => {
    const { data } = await apollo.query({
      fetchPolicy: 'no-cache',
      query: socialProfileFindMany,
      variables: { user }
    })

    return data.socialProfileFindMany
  }

  const loadPersonas = async () => {
    const { data } = await apollo.query({
      fetchPolicy: 'no-cache',
      query: getPersonas
    })

    return data?.getPersonas || []
  }

  const loadContentPillars = async () => {
    const { data } = await apollo.query({
      fetchPolicy: 'no-cache',
      query: getContentPillars
    })

    return data?.getContentPillars || []
  }

  const loadWorkspaces = async () => {
    const { data } = await apollo.query({
      fetchPolicy: 'no-cache',
      query: getWorkspaces
    })

    return data.getWorkspaces
  }

  // Fetches the user document and updates the state
  const getUser = async (): Promise<UserType | null> => {
    const collection = firebase.firestore().collection('users')
    const userProfile = auth.currentUser
    const snap = await collection.doc(userProfile?.uid).get()
    const userObj = { id: snap.id, ...snap.data() } as UserType
    const tokenResult = await firebase.auth().currentUser?.getIdTokenResult()

    if (!snap.exists || !userProfile) {
      return null
    }

    setCookie('userId', userObj.id)
    setCookie('jwt', tokenResult?.token || '')

    return {
      ...userObj,
      socialProfiles: await getSocialProfiles(userObj.id),
      contentPillars: await loadContentPillars(),
      personas: await loadPersonas(),
      workspaces: await loadWorkspaces()
    }
  }

  useEffect(
    () =>
      auth.onAuthStateChanged(async (newUser) => {
        // if cached user is not equal to actual user or user ID is not cached
        const differentCachedUser = newUser?.email !== syncUserData?.email

        if (!syncUserData?.id || differentCachedUser) {
          setUser(null)
          setLoaded(false)
        }

        if (!newUser) {
          setLoaded(true)
          return setUser(null)
        }

        const userData = await getUser()

        const token = await auth.currentUser?.getIdTokenResult()
        setCookie('jwt', token?.token || '')

        setUser(userData)
        setCurrentUserData(userData)
        setLoaded(true)
      }),
    []
  )

  useEffect(() => {
    if (!!user?.id) {
      identify(user)
      updateIntercomUser(user)

      window.profitwell('start', { user_id: user?.id })
    }
  }, [user, loaded])

  useEffect(() => {
    setLoading(!loaded)
  }, [loaded])

  useEffect(() => {
    if (!!user?.id) {
      page()
    }
  }, [user, loaded, location.pathname])

  return {
    user,
    loaded,
    loading,
    logout,
    refresh
  }
}
